<script>
import Form, { formCreate } from '../../../../components/form';

export default {
  props: {
    themeColor: {
      type: String,
      default: '#409eff',
    },
    siderColor: {
      type: String,
      default: 'rgb(27, 29, 70)',
    },
  },
  extends: Form,
  data() {
    return {
      rule: [
        {
          title: '主题',
          field: 'theme',
          type: 'ThemePicker',
          on: {
            change: (val) => this.themeChange('theme', val),
          },
          props: {
            defaultColor: this.themeColor,
            syncField: [
              'btn',
            ],
          },
          value: [
            {
              key: 'background-color',
              index: 10,
              list: [
                '.el-tag',
                '.el-button--primary',
                '.vxe-table .vxe-body--row.row--current',
              ],
            },
            {
              key: 'background-color',
              index: 5,
              list: [
                '.el-tag__close:hover',
              ],
            },
            {
              key: 'background-color',
              index: 2,
              list: [
                '.el-tag--dark',
                '.el-menu-item.is-active',
                '.is--checked.vxe-custom--option .vxe-checkbox--icon:before',
                '.is--checked.vxe-export--panel-column-option .vxe-checkbox--icon:before',
                '.is--checked.vxe-table--filter-option .vxe-checkbox--icon:before',
                '.is--indeterminate.vxe-custom--option .vxe-checkbox--icon:before',
                '.is--indeterminate.vxe-export--panel-column-option .vxe-checkbox--icon:before',
                '.is--indeterminate.vxe-table--filter-option .vxe-checkbox--icon:before',
                '.vxe-table .is--checked.vxe-cell--checkbox .vxe-checkbox--icon:before',
                '.vxe-table .is--indeterminate.vxe-cell--checkbox .vxe-checkbox--icon:before',
                '.el-button--primary',
              ],
            },
            {
              key: 'color',
              index: 2,
              list: [
                '.el-tag',
                '.formatter-view',
                '.el-tag__close',
                '.el-button--text',
                'a',
                '.setting-btn .el-dropdown-link',
                '.el-button--primary',
                '.el-dropdown-menu__item',
                '.is--checked.vxe-custom--option',
                '.is--checked.vxe-export--panel-column-option',
                '.is--checked.vxe-table--filter-option',
                '.is--indeterminate.vxe-custom--option',
                '.is--indeterminate.vxe-export--panel-column-option',
                '.is--indeterminate.vxe-table--filter-option',
                '.vxe-table .is--checked.vxe-cell--checkbox',
                '.vxe-table .is--indeterminate.vxe-cell--checkbox',
                '.el-pager li.active',
              ],
            },
            {
              key: 'color',
              index: 10,
              list: [
                '.el-tag--dark',
                '.el-tag--dark .el-tag__close',
                '.el-button--primary',
              ],
            },
            {
              key: 'border-color',
              index: 10,
              list: [
                '.el-tag',
                '.el-button--primary',
              ],
            },
            {
              key: 'border-color',
              index: 2,
              list: [
                '.is--checked.vxe-custom--option .vxe-checkbox--icon:before',
                '.is--checked.vxe-export--panel-column-option .vxe-checkbox--icon:before',
                '.is--checked.vxe-table--filter-option .vxe-checkbox--icon:before',
                '.is--indeterminate.vxe-custom--option .vxe-checkbox--icon:before',
                '.is--indeterminate.vxe-export--panel-column-option .vxe-checkbox--icon:before',
                '.is--indeterminate.vxe-table--filter-option .vxe-checkbox--icon:before',
                '.vxe-table .is--checked.vxe-cell--checkbox .vxe-checkbox--icon:before',
                '.vxe-table .is--indeterminate.vxe-cell--checkbox .vxe-checkbox--icon:before',
                '.vxe-cell--checkbox:hover',
                '.vxe-table .vxe-cell--checkbox:not(.is--disabled):hover .vxe-checkbox--icon:before',
              ],
            },
            {
              key: 'background-color',
              index: 'not',
              list: [
                '.el-button--text',
              ],
            },

            {
              key: 'border-color',
              index: 'not',
              list: [
                '.el-button--text',
              ],
            },
          ],
        },
        {
          title: '侧边栏',
          field: 'sider',
          type: 'ThemePicker',
          on: {
            change: (val) => this.themeChange('sider', val),
          },
          props: {
            defaultColor: this.siderColor,
          },
          value: [
            {
              key: 'background-color',
              index: 2,
              list: ['.el-aside', '.el-menu-vertical-demo', '.el-submenu__title', '.el-menu-item'],
            },
          ],
        },
      ],
      cssList: {},
    };
  },
  mounted() {

  },
  methods: {
    themeChange(field, val) {
      const styles = [].slice.call(document.querySelectorAll('style')) // 查找所有样式节点, 将 NodeList 转变成数组, 筛选元素 innerText 中包含旧数值的元素
        .find((style) => {
          const text = style.innerText;
          return /crm-setting-css/.test(style.innerText);
        });
      this.cssList[field] = val;
      // console.log(this.cssList);
      let cssStyle = '';
      for (const i in this.cssList) {
        if (this.cssList[i]) {
          // console.log(i);
          cssStyle += this.cssList[i];
        }
      }
      styles.innerText = `/* crm-setting-css */${cssStyle}`;
      // console.log(styles.innerText);
    },
  },
};
</script>
